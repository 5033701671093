import img_1 from "../../public/assets/img/blog/blog-1.jpg"
import img_2 from "../../public/assets/img/blog/blog-2.jpg"
import img_3 from "../../public/assets/img/blog/blog-3.jpg"

import author_img_1 from "../../public/assets/img/blog/blog-avata-1.png";
import author_img_2 from "../../public/assets/img/blog/blog-avata-2.png";
import author_img_3 from "../../public/assets/img/blog/blog-avata-3.png";

import img_4 from "../../public/assets/img/blog/blog-4-1.jpg"
import img_5 from "../../public/assets/img/blog/blog-4-2.jpg"

const blog_data = [
    // home 03 
    {
        id: 1, 
        img: img_1,
        category: "Crm Software",
        color: "1",
        date: "28 April, 2023",
        title: <>How Chatbots Can Help You <br /> Drive More Sales</>,
        author_img: author_img_1,
        author_name: "Hilary Ouse",
        job_title: "Founder & CEO Dulalix"
    },
    {
        id: 2, 
        img: img_2,
        category: "Sales Tools",
        color: "2",
        date: "28 April, 2023",
        title: <>6 Tips for Personal Selling that Guarantee Success in 2023</>,
        author_img: author_img_2,
        author_name: "Rudra Ghosh",
        job_title: "Founder & CEO Dulalix"
    },
    {
        id: 3, 
        img: img_3,
        category: "Sales Management",
        color: "3",
        date: "28 April, 2023",
        title: <>How Chatbots Can Help You <br /> Drive More Sales</>,
        author_img: author_img_3,
        author_name: "Penny Tool",
        job_title: "Founder & CEO Dulalix"
    },

    // home 04
    {
        id: 4, 
        img: img_4,
        category: " Digital Marketing",
        color: "",
        date: "",
        title: <>Boost Your ROI: Proven Digital Marketing Tactics for UAE Businesses</>,
        description: <>With increased competition in today's market, especially in the UAE. It has become essential for business practitioners to partner with the best digital marketing agency in UAE.</>,
        author_img: author_img_3,
        author_name: "Penny Tool",
        job_title: "Founder & CEO Dulalix"
    },
    {
        id: 5, 
        img: img_5,
        category: "Social Media Marketing",
        color: "",
        date: "",
        title: <>How to Dominate the UAE Market with Effective Social Media Strategies</>,
        description: <>The United Arab Emirates (UAE) is a fast-developing market with great potential. It is a well-organized country with people of different origins and one of the developed economies.</>,
        author_img: author_img_3,
        author_name: "Penny Tool",
        job_title: "Founder & CEO Dulalix"
    },

    // anything here


]
export default blog_data